/* eslint-disable no-undef */
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"

import Appsignal from 'utils/appsignal';
import { installErrorHandler } from "@appsignal/stimulus"

import EmailValidationController from 'controllers/email_validation_controller';
import BeaconLinkController from 'controllers/beacon_link_controller';
import FormController from 'controllers/form_controller';
import PreviewTargetController from 'controllers/preview_target_controller';
import ExtraSupportController from 'controllers/extra_support_controller';
import ExpandableContentController from 'controllers/expandable_content_controller';
import VisibilityToggleController from 'controllers/visibility_toggle_controller';
import AmountToggleController from 'controllers/amount_toggle_controller';
import StripeConfirmController from 'controllers/stripe_confirm_controller';
import StripePaymentController from 'controllers/stripe_payment_controller';
import LoginController from 'controllers/login_controller';
import LoginCodeController from 'controllers/login_code_controller';
import CopyToClipboardController from 'controllers/copy_to_clipboard_controller';

const application = Application.start()
// Report errors to appsignal
installErrorHandler(Appsignal, application);

// Whitelist the controllers we want to use.
application.register("email-validation", EmailValidationController);
application.register("beacon-link", BeaconLinkController);
application.register("form", FormController);
application.register("preview-target", PreviewTargetController);
application.register("extra-support", ExtraSupportController);
application.register("expandable-content", ExpandableContentController);
application.register("visibility-toggle", VisibilityToggleController);
application.register("amount-toggle", AmountToggleController);
application.register("stripe-confirm", StripeConfirmController);
application.register("stripe-payment", StripePaymentController);
application.register("login", LoginController);
application.register("login-code", LoginCodeController);
application.register("copy-to-clipboard", CopyToClipboardController);

import { definitions as subscriberV2Components } from "stimulus:../../../../../engines/subscriber_v2/app/components/subscriber_v2";
application.load(subscriberV2Components);

// Still load all the component controllers for now.
import { definitions as contextComponents } from "stimulus:../../../../components";
application.load(contextComponents);